/** This file is autogenerated using the staging environment */
    
import type { DefaultsEntry } from './config.types';
    
export { config } from './staging.config';
export const defaults: DefaultsEntry = {
  confirmations: {
    donation: 2,
    wirePayout: 2,
    walletPayout: 2,
    portfolioTrade: 2,
    transfer: 2,
    setManager: 2,
    orgDeployment: 2,
    fundDeployment: 2,
  },
  fees: {
    donationBps: 150,
    transferBps: 100,
    stockDonationFeeBps: 150,
    tgbEntityStockDonationFeeBps: 150,
  },
  network: {
    defaultChainId: 11155111,
    supportedNetworkSettings: [
      {
        chainId: 11155420,
        baseToken: {
          id: 100,
          symbol: 'USDC',
          name: 'USDC',
          decimals: 6,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: false,
          popularity: 0,
          chainId: 11155420,
          contractAddress: '0x5fd84259d66cd46123540766be93dfe6d43130d7',
        },
        nativeToken: {
          id: 99,
          symbol: 'ETH',
          name: 'Ether',
          decimals: 18,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: false,
          popularity: 0,
          chainId: 11155420,
          contractAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        },
        contracts: {
          registry: '0xc9210b825e94a8e4e600f6d2d7eecbe3e2db948c',
          orgFundFactory: '0x4b6f9fc1b0ec4971e76777abc3af9a188cb080d2',
          batchOrgDeployer: '0x0000000000000000000000000000000000000000',
          transferDeployer: '0x2b895957466eb66bdef7123de5191a6b8057afc0',
          entityMulticall: '0x09dc740a61999f20381d6e7b4fe81ff33e05de19',
        },
      },
      {
        chainId: 11155111,
        baseToken: {
          id: 85,
          symbol: 'USDC',
          name: 'USDC',
          decimals: 6,
          logoUrl: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
          type: 'EvmToken',
          featured: false,
          popularity: 0,
          chainId: 11155111,
          contractAddress: '0x1c7d4b196cb0c7b01d743fbc6116a902379c7238',
        },
        nativeToken: {
          id: 84,
          symbol: 'ETH',
          name: 'Ether',
          decimals: 18,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: false,
          popularity: 0,
          chainId: 11155111,
          contractAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        },
        contracts: {
          registry: '0x9ac17eedd5445c49f8cef84229537d217932fb41',
          orgFundFactory: '0xa07ef4c2923162850cb590d97baa0555a0e82a50',
          batchOrgDeployer: '0xce83fac23073b324af5a4f2d33424427a3df42da',
          transferDeployer: '0xc822bd84a4bb807f2f1a6157ed37f66f0e30aaf2',
          entityMulticall: '0xeead1f7eac951cc218c483fb0b5345074c9b6404',
        },
      },
      {
        chainId: 84532,
        baseToken: {
          id: 91,
          symbol: 'USDC',
          name: 'USDC',
          decimals: 6,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: false,
          popularity: 0,
          chainId: 84532,
          contractAddress: '0x036cbd53842c5426634e7929541ec2318f3dcf7e',
        },
        nativeToken: {
          id: 89,
          symbol: 'ETH',
          name: 'Ether',
          decimals: 18,
          logoUrl: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
          type: 'EvmToken',
          featured: false,
          popularity: 0,
          chainId: 84532,
          contractAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        },
        contracts: {
          registry: '0xb2c41ea48805a3c922f2393620c67ba5744c46b6',
          orgFundFactory: '0x0633761ce799f3a6b433794c487f5f5121f6d11b',
          batchOrgDeployer: '0x0000000000000000000000000000000000000000',
          transferDeployer: '0xaf8afaa317faf368b5f2e300f2b064251cc06c11',
          entityMulticall: '0x9ac17eedd5445c49f8cef84229537d217932fb41',
        },
      },
    ],
  },
  roles: {
    keeper: [
      '0x88100996d867585258f88b01cfe44cb60119eef0',
      '0xbf8e57a8195eed88f7663acf2af0b7eacdbd69bc',
      '0x9ba4a587e757c8c6e3e2063c8634fc1d24f4a675',
      '0x0cb1d93daec77df2ed7db31c040fd2174452bd9f',
      '0xbb77d2c8c6fec55285c782356333afdcd4a926d8',
    ],
    accountant: ['0x9928fa2b51f7e2cce76cf0a6aed1b7e4afe0cc0f', '0x9d5025b327e6b863e5050141c987d988c07fd8b2'],
    maintainer: [
      '0xc6f1beb66fa3d40b818f969d219958947cd4b448',
      '0x9ba4a587e757c8c6e3e2063c8634fc1d24f4a675',
      '0x5398785700a9248cfc2101f0188c9788e8619974',
      '0x77dfb7afae21622274751358d01ad5f1d59d202a',
      '0xc8b810fe39952aa65ca5a9387a3546b9b6bf5780',
      '0x61752014a3e9d04c3ea5de61fed4667ca43676d5',
    ],
    reviewer: ['0x78062b69cffd97e6e0bba4cd71b0ec436b634e10'],
  },
  stripePublishableKey:
    'pk_test_51NlG4xJjtfgbRoqp8AfiMB541cU3Lk7rtkKV7CnfwTjdfSTalCmV67Rvq9DeXDBXQlmhRAaGtXCAkEzFjATx9XlF00W0onSQYL',
};
